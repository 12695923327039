<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Petty Cash</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="UploadImage" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    class="form-control"
                    @change="onImageSelected"
                  />
                  <img :src="form.file" alt="" style="height:0px" />
                  <small class="text-danger" v-if="errors.file">{{
                    errors.file[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "FormPettyCash",
  data() {
    return {
      name: "Form Petty Cash File",
      form: {
        file: null,
      },
      errors: {},
      isSubmitting: false,
    };
  },
  methods: {
    onImageSelected(event) {
      this.form.file = event.target.files[0];
    },
    UploadImage() {
      this.isSubmitting = true;
      let id = this.$route.params.id;
      let formData = new FormData();
      formData.set("file", this.form.file);

      axios
        .post(this.$store.state.api + "petty_cash/update/" + id, formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your petty cash image has been saved!");
          this.$router.go(-1);
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
