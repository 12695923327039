import PettyCash from './PettyCash.vue'
import PettyCashCreate from './Create.vue'
import PettyCashShow from './Show.vue'
import PettyCashLog from './DetailLog.vue'
import PettyCashUpload from './Upload.vue'

export default [
    {
        path: '/pettycash',
        name: 'PettyCash',
        component: PettyCash
    },
    {
        path: '/pettycash/create',
        name: 'PettyCashCreate',
        component: PettyCashCreate
    },
    {
        path: '/pettycash/:id',
        name: 'PettyCashShow',
        component: PettyCashShow
    },
    {
        path: '/pettycash/logs/:id',
        name: 'PettyCashLog',
        component: PettyCashLog
    },
    {
        path: '/pettycash/upload/:id',
        name: 'PettyCashUpload',
        component: PettyCashUpload
    },
]
