<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table><h1>Petty Cash</h1></template>
      <template v-slot:item-slot>
        <router-link class="breadcrumb-item" :to="{ name: 'PettyCash' }"
          >Petty Cash</router-link
        >
      </template>
    </base-header>
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div class="section-body" v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4>
                Detail
              </h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Created By</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="petty_cash.log.created_by_name"
                />
              </div>
              <div class="form-group">
                <label>Payment Type</label>
                <input type="text" class="form-control" :value="petty_cash.log.payment_type_name" disabled>
              </div>
              <div class="form-row">
                <div class="col">
                  <label>Status</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="petty_cash.log.status"
                  />
                </div>
                <div class="col">
                  <label>Created At</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="petty_cash.log.created_at"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Money {{ petty_cash.log.status }}</label>
                <h4>Rp. {{ formatPrice(petty_cash.log.change) }}</h4>
              </div>
              <div class="form-group">
                <label>Total Money</label>
                <h3>Rp. {{ formatPrice(petty_cash.log.nominal) }}</h3>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  :checked="petty_cash.log.is_closing == true"
                />
                <label class="form-check-label">Tutup Buku</label>
              </div>
              <div class="form-group">
                <a :href="petty_cash.log.file" target="_blank">
                  <h5>Show File</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="petty_cash.fragments.length != 0">
          <div class="card">
            <div class="card-header">
              <h4>History</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="petty_cash"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
              >
                <template v-slot:item="row">
                  <tr v-if="row.item.qty != 0">
                    <td>{{ row.item.created_at }}</td>
                    <td>
                      Rp. {{ formatPrice(row.item.nominal) }} x
                      {{ row.item.qty }}
                    </td>
                    <td>Rp. {{ formatPrice(row.item.nominal_qty) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="7"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "PettyCashShow",
  data() {
    return {
      petty_cash: [],
      errors: [],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      isSubmitting: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      axios
        .get(this.$store.state.api + "petty_cash/logs/" + id, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => (this.petty_cash = res.data.data))
        .catch((err) => console.log(err));
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Created At",
          value: "created_at",
        },
        {
          text: "Nominal",
          value: "nominal",
        },
        {
          text: "Total",
          value: "nominal_qty",
        },
      ];
    },
  },
};
</script>
